import React from 'react';
import { graphql } from "gatsby";
import Img from 'gatsby-image';
import MainImage from "../components/MainImage";
import Layout from "../components/Layout";
import GraphQLErrorList from "../components/GraphQLErrorList";
import componentMapper from "../components/componentMapper";
import { getFixedProps } from '../utils/image-url';

export const query = graphql`
    query integrationPagesQuery($id: String!) {
        page: sanityPage(id: { eq: $id }) {
            ...PageInfo
        }
        settings: sanitySiteSettings {
            title
            id
            facebook
            instagram
            twitter
            _rawOpenGraph(resolveReferences: {maxDepth: 10})
            _rawLogo(resolveReferences: {maxDepth: 10})
        }
        footer: sanitySiteFooter {
            _rawContent(resolveReferences: {maxDepth: 10})
            _rawLinks(resolveReferences: {maxDepth: 10})
            _rawSlug(resolveReferences: {maxDepth: 10})
        }
    }
`

const Integration = ({ data, errors }) => {
  if (errors) {
    return (
      <GraphQLErrorList errors={errors} />
    )
  }

  function autoPlay() {
    var v = this;
    setTimeout(function() {
      v.play();
    }, 300);
  }

  const { _rawContent, _rawOpenGraph } = data.page;
  const [hero, ...other] = _rawContent;

  const children = other.map((block) => {
    const Component = componentMapper(block._type);
    return (
      <Component key={block._key} data={block} />
    )
  })

  return (
    <Layout
      title={_rawOpenGraph?.title}
      description={_rawOpenGraph?.description}
      settings={data.settings} footer={data.footer}>
      <div className="section-spacer text-center">
        <div className="row">
          <div className="col">
            <picture className="integration-header">
              <img
                src="/assets/images/icons/tmg-icon.png"
                alt="Text My Gym Integration"
              />
              <span className="integration-plus">+</span>
              <Img fixed={getFixedProps(hero.illustration.image.asset, 100)} />
            </picture>
          </div>
        </div>
      </div>
      <div className="section-spacer">
        <div className="row">
          <div className="col col-md-14 col-md-offset-1">
            <h1 className="xl text-blue">
              {hero.heading}
            </h1>
            <p className="p">
              {hero.tagline}
            </p>
          </div>
        </div>
      </div>
      {children}
    </Layout>
  )
};

export default Integration;
